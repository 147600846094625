<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>

		<el-card class="box-card order-detail">
			<div slot="header" class="clearfix">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/member/order_sale_list' }">竞买竞卖</el-breadcrumb-item>
					<el-breadcrumb-item>交易历史</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div v-loading="loading">
				 <div class="hislist">
					 <nav>
					 	<li>订单编号</li>
					 	<li>买方</li>
					 	<li>卖方</li>
					 	<li>商品信息</li>
					 	<li>售价</li>
					 	<li>数量</li>
					 	<li>备注</li> 
					 	<li>时间</li> 
					 	<li>操作</li>
					 </nav>
					 <ul v-for="(hitem,hindex) in orderDetail" :class="hitem.iscur==1?'cur':''">
						 <li>{{hitem.order_no}}</li>
						 <li>{{hitem.buyer_name}}</li>
						 <li>{{hitem.seller_name}}</li>
						 <li>{{hitem.goods_name}}</li>
						 <li>{{hitem.price}}</li>
						 <li>{{hitem.sum}}</li>
						 <li>{{hitem.detail}}</li>
						 <li>{{hitem.create_time}}</li>
						 <li><el-link v-if="hitem.order_id>0" :underline="false" @click="toOrderDetail(hitem)">订单详情</el-link></li>
					 </ul>
				 </div>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		apiOrderSaleList
	} from '@/api/order/order';
	import orderMethod from '@/utils/orderMethod';
	import {
		mapGetters
	} from 'vuex';
	export default {
		name: 'order_detail',
		components: {},
		mixins: [orderMethod],
		data: () => {
			return {
				orderId: 0,
				my_order_no:0,
				orderDetail: null,
				loading: true,
				yes: true
			};
		},
		created() {
			this.orderId = this.$route.query.order_id;
			this.my_order_no = this.$route.query.my_order_no;
			this.getOrderDetail();
		},
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
		computed: {
			...mapGetters(['defaultGoodsImage'])
		},
		methods: {
			getOrderDetail() {
				apiOrderSaleList({
						order_id: this.orderId,
						my_order_no:this.my_order_no
					})
					.then(res => {
						if (res.code >= 0) {
							this.orderDetail = res.data;
							//console.log("..................",JSON.stringify(this.orderDetail))
							this.loading = false;
						} else {
							this.$message({
								message: '未获取到订单信息',
								type: 'warning',
								duration: 2000,
								onClose: () => {
									this.$router.push({
										path: '/member/order_list'
									});
								}
							});
						}
					})
					.catch(err => {
						this.loading = false;
						this.$message.error({
							message: err.message,
							duration: 2000,
							onClose: () => {
								this.$router.push({
									path: '/member/order_list'
								});
							}
						});
					});
			},
			imageError(index) {
				this.orderDetail.order_goods[index].sku_image = this.defaultGoodsImage;
			},
			toOrderDetail(data){ 
				this.$router.push({
					path: '/member/order_detail',
					query: {
						order_id: data.order_id
					}
				});
			}
		},
		filters: {
			abs(value) {
				return Math.abs(parseFloat(value)).toFixed(2);
			}
		}
	};
</script>
<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
	}

	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}

	.order-detail {
		.order-status {
			background-color: #fff;
			margin-bottom: 20px;

			h4 {
				margin: 10px 0 20px;
				border-bottom: 1px solid #eeeeee;
				padding-bottom: 10px;
			}

			.go-pay {
				p {
					display: inline-block;
					vertical-align: middle;

					span {
						font-weight: bold;
						color: $base-color;
						font-size: 18px;
					}
				}
			}

			.operation {
				margin-top: 10px;
			}
		}

		.order-info {
			background-color: #fff;
			margin-bottom: 10px;

			h4 {
				margin: 10px 0 20px;
				border-bottom: 1px solid #eeeeee;
				padding-bottom: 10px;
			}

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					flex: 0 0 33.3333%;
					margin-bottom: 10px;

					&:last-child {
						flex: initial;
					}
				}
			}
		}

		.take-delivery-info {
			background-color: #fff;
			margin-bottom: 10px;

			h4 {
				margin: 10px 0 20px;
				border-bottom: 1px solid #eeeeee;
				padding-bottom: 10px;
			}

			ul {
				display: flex;
				flex-wrap: wrap;

				li {
					flex: 0 0 33.3333%;
					margin-bottom: 10px;

					&:last-child {
						flex: initial;
					}
				}
			}
		}

		nav {
			overflow: hidden;
			padding: 10px 0;
			background: #fff;
			border-bottom: 1px solid #eeeeee;

			li {
				float: left;

				&:nth-child(1) {
					width: 20%;
				}
					
				&:nth-child(2) {
					width: 10%;
				}
					
				&:nth-child(3) {
					width: 10%;
				}
				
				&:nth-child(4) {
					width: 10%;
				}
				&:nth-child(5) {
					width: 10%;
				}
				&:nth-child(6) {
					width: 6%;
				}
				&:nth-child(7) {
					width: 8%;
				}
				&:nth-child(8) {
					width: 16%;
				}
				&:nth-child(9) {
					width: 10%;
				}
			}
		}
        .hislist{
			ul{
				height:35px;
				line-height:35px;
				li{
					float: left;
					
					&:nth-child(1) {
						width: 20%;
					}
						
					&:nth-child(2) {
						width: 10%;
					}
						
					&:nth-child(3) {
						width: 10%;
					}
					
					&:nth-child(4) {
						width: 10%;
					}
					&:nth-child(5) {
						width: 10%;
					}
					&:nth-child(6) {
						width: 6%;
					}
					&:nth-child(7) {
						width: 8%;
					}
					&:nth-child(8) {
						width: 16%;
					}
					&:nth-child(9) {
						width: 10%;
					}
				}
			}
			.cur{
				background:#ffccee;
			}
		}
		.list {
			border-bottom: 1px solid #eeeeee;

			.item {
				background-color: #fff;
				padding: 10px 0;
				overflow: hidden;

				li {
					float: left;
					line-height: 60px;

					&:nth-child(1) {
						width: 50%;
						line-height: inherit;

						&.no-operation {
							width: 60%;
						}

						.img-wrap {
							width: 60px;
							height: 60px;
							float: left;
							margin-right: 10px;
							cursor: pointer;
						}

						.info-wrap {
							margin-left: 70px;

							h5 {
								font-weight: normal;
								font-size: $ns-font-size-base;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
								overflow: hidden;
								margin-right: 10px;
								display: inline-block;
								cursor: pointer;

								&:hover {
									color: $base-color;
								}
							}

							span {
								font-size: $ns-font-size-sm;
								color: #9a9a9a;
							}
						}
					}

					&:nth-child(2) {
						width: 15%;
					}

					&:nth-child(3) {
						width: 10%;
					}

					&:nth-child(4) {
						width: 15%;
					}

					&:nth-child(5) {
						width: 10%;
					}
				}
			}
		}

		// 总计
		.total {
			padding: 20px;
			background-color: #fff;
			text-align: right;

			li {
				span {
					width: 150px;
					display: inline-block;
				}

				&.pay-money {
					font-weight: bold;

					span {
						color: $base-color;
						font-size: 16px;
						vertical-align: middle;
					}
				}
			}
		}
	}
</style>
